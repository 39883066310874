"use strict";

var _interopRequireDefault = require("/Users/tanglewang/htdocs/yqd/yqdshop_h5/node_modules/_@babel_runtime@7.17.8@@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AddProfile = AddProfile;
exports.getGoodsDetail = getGoodsDetail;
exports.getGoodsList = getGoodsList;
exports.getGoodsProfile = getGoodsProfile;
exports.getProfileDetail = getProfileDetail;

var _defineProperty2 = _interopRequireDefault(require("/Users/tanglewang/htdocs/yqd/yqdshop_h5/node_modules/_@babel_runtime-corejs2@7.17.8@@babel/runtime-corejs2/helpers/defineProperty.js"));

var _request = _interopRequireDefault(require("@/utils/request"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

/**
 *@param {obj} searchObj
 * @param {int} page
 * @param {int} page_size
 */
function getGoodsList(searchObj) {
  var _ref = searchObj || {},
      _ref$page = _ref.page,
      page = _ref$page === void 0 ? 1 : _ref$page,
      _ref$page_size = _ref.page_size,
      page_size = _ref$page_size === void 0 ? 10 : _ref$page_size;

  return (0, _request.default)({
    url: '/goods',
    method: 'get',
    params: _objectSpread(_objectSpread({}, searchObj), {}, {
      page: page,
      page_size: page_size
    }),
    showLoading: false
  });
}
/**
 * 详情
 * @param {int} id
 */


function getGoodsDetail(id) {
  return (0, _request.default)({
    url: '/goods/' + id,
    method: 'get'
  });
}
/**
 * 详情
 * @param {int} id
 */


function AddProfile(id, data) {
  return (0, _request.default)({
    url: '/goods/addprofile/' + id,
    method: 'post',
    data: data
  });
}
/**
 * 资料详情
 * @param {int} id
 */


function getProfileDetail(id) {
  return (0, _request.default)({
    url: '/goods/getprofile/' + id,
    method: 'get'
  });
}
/**
 * 资料详情
 * @param {int} id
 */


function getGoodsProfile(goods_id) {
  return (0, _request.default)({
    url: '/goods/getgoodsprofile/' + goods_id,
    method: 'get'
  });
}