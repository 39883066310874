"use strict";

var _interopRequireDefault = require("/Users/tanglewang/htdocs/yqd/yqdshop_h5/node_modules/_@babel_runtime@7.17.8@@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bind = bind;
exports.captcha = captcha;
exports.changePassword = changePassword;
exports.changePhone = changePhone;
exports.checkIdentityVerify = checkIdentityVerify;
exports.disbindwechat = disbindwechat;
exports.getIncomeDetail = getIncomeDetail;
exports.getIncomeList = getIncomeList;
exports.getInfo = getInfo;
exports.getInsureList = getInsureList;
exports.getPerformanceDetail = getPerformanceDetail;
exports.getPerformanceList = getPerformanceList;
exports.getProductCompany = getProductCompany;
exports.insure = insure;
exports.login = login;
exports.logout = logout;
exports.stats = stats;
exports.userAddressAdd = userAddressAdd;
exports.userAddressDel = userAddressDel;
exports.userAddressEdit = userAddressEdit;
exports.userAddressInfo = userAddressInfo;
exports.userAddressList = userAddressList;
exports.userIdentityVerifyCaptcha = userIdentityVerifyCaptcha;
exports.userLogout = userLogout;
exports.userProfile = userProfile;

var _defineProperty2 = _interopRequireDefault(require("/Users/tanglewang/htdocs/yqd/yqdshop_h5/node_modules/_@babel_runtime-corejs2@7.17.8@@babel/runtime-corejs2/helpers/defineProperty.js"));

var _request = _interopRequireDefault(require("@/utils/request"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function login(data) {
  return (0, _request.default)({
    url: '/login',
    method: 'post',
    data: data
  });
}

function getInfo(token) {
  return (0, _request.default)({
    url: '/user',
    method: 'get'
  });
}

function logout() {
  return (0, _request.default)({
    url: '/user/logout',
    method: 'post'
  });
}

function userProfile(data) {
  return (0, _request.default)({
    url: '/user/profile',
    method: 'post',
    data: data
  });
}

function userAddressAdd(data) {
  return (0, _request.default)({
    url: '/user/contact',
    method: 'post',
    data: data
  });
}

function userAddressEdit(id, data) {
  return (0, _request.default)({
    url: "/user/contact/".concat(id),
    method: 'put',
    data: data
  });
}

function userAddressInfo(id) {
  return (0, _request.default)({
    url: "/user/contact/".concat(id),
    method: 'get'
  });
}

function userAddressList(data) {
  return (0, _request.default)({
    url: "/user/contact",
    method: 'get',
    data: data
  });
}

function userAddressDel(id, data) {
  return (0, _request.default)({
    url: "/user/contact/".concat(id),
    method: 'delete',
    data: data
  });
}

function userIdentityVerifyCaptcha(data) {
  return (0, _request.default)({
    url: "/user/identityverify/captcha",
    method: 'post',
    data: data
  });
}

function captcha(data) {
  return (0, _request.default)({
    url: "/captcha",
    method: 'post',
    data: data
  });
}

function bind(data) {
  return (0, _request.default)({
    url: "/user/bind",
    method: 'post',
    data: data
  });
}

function disbindwechat() {
  return (0, _request.default)({
    url: "/user/disbindwechat",
    method: 'post'
  });
}

function checkIdentityVerify(data) {
  return (0, _request.default)({
    url: "/user/identityverify",
    method: 'post',
    data: data
  });
}

function changePhone(data) {
  return (0, _request.default)({
    url: "/user/changephone",
    method: 'post',
    data: data
  });
}

function changePassword(data) {
  return (0, _request.default)({
    url: "/user/changepassword",
    method: 'post',
    data: data
  });
}

function insure(data) {
  return (0, _request.default)({
    url: "/user/insure",
    method: 'post',
    data: data
  });
}

function getInsureList(data) {
  return (0, _request.default)({
    url: "/user/insure",
    method: 'get',
    data: data
  });
}

function getIncomeList(data) {
  return (0, _request.default)({
    url: "/user/income",
    method: 'get',
    params: _objectSpread({}, data)
  });
}

function getIncomeDetail(data) {
  return (0, _request.default)({
    url: "/user/income/detail",
    method: 'get',
    params: _objectSpread({}, data)
  });
}

function stats(data) {
  return (0, _request.default)({
    url: "/user/stats",
    method: 'get',
    data: data
  });
}

function userLogout(data) {
  return (0, _request.default)({
    url: "/user/logout",
    method: 'post',
    data: data
  });
}

function getPerformanceList(data) {
  return (0, _request.default)({
    url: "/user/performance",
    method: 'get',
    params: _objectSpread({}, data)
  });
}

function getPerformanceDetail(data) {
  return (0, _request.default)({
    url: "/user/performance/detail",
    method: 'get',
    params: _objectSpread({}, data)
  });
}

function getProductCompany(data) {
  return (0, _request.default)({
    url: "/user/product/company",
    method: 'get',
    params: _objectSpread({}, data)
  });
}