"use strict";

var _interopRequireDefault = require("/Users/tanglewang/htdocs/yqd/yqdshop_h5/node_modules/_@babel_runtime@7.17.8@@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fastcreatebeta = fastcreatebeta;
exports.fastcreatebetaScan = fastcreatebetaScan;

var _request = _interopRequireDefault(require("@/utils/request"));

function fastcreatebetaScan(data) {
  return (0, _request.default)({
    url: '/admin/member/miniprogram/fastcreatebetaScan',
    method: 'post',
    data: data
  });
}

function fastcreatebeta(data) {
  return (0, _request.default)({
    url: '/admin/member/miniprogram/fastcreatebeta',
    method: 'post',
    data: data
  });
}